<template>
  <aside>
    <header>
      <h2>Workday Integration</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Credentials</h4>
          <p>Workday web services user credentials.</p>
        </div>
        <div class="fields">
          <template v-if="active && !add_creds">
            <div class="workday-auth active-creds">
              <div class="d-flex cred-row align-items-center">
                <i class="fa fa-globe"></i>
                <div>
                  {{integration.configuration.report_url}}
                </div>
              </div>
              <div class="d-flex cred-row align-items-center">
                <i class="fa fa-user"></i>
                <div>
                  {{integration.configuration.username}}
                </div>
              </div>

              <a href="#" @click.prevent="add_creds=true;">(update credentials)</a>
            </div>
          </template>
          <form class="dayforce-auth" v-else @submit.prevent="tryAuth">
            <p>Your Workday credentials are securely saved for future syncing.</p>
            <div class="form-group">
              <label for="company">Workday Report JSON URL</label>
              <span id="report_url" class="secure-field"></span>
            </div>

            <div class="form-group">
              <label for="username">Workday Username</label>
              <span id="username" class="secure-field"></span>
            </div>

            <div class="form-group">
              <label for="password">Workday Password</label>
              <span id="password" class="secure-field"></span>
            </div>

            <button class="btn btn-dayforce btn-block" :class="{'loading': loading}" :disabled="loading">Connect to Workday</button>
            
          </form>
        </div>
      </div>

      <div class="setting-row" v-if="active">
        <div class="title">
          <h4>Statistics</h4>
          <p>Latest details about your integration.</p>
        </div>
        <div class="fields stats">
          <ul>
            <li class="status">
              <strong>Status</strong>
              <span :class="[integration.status]"><span>{{status_label}}</span></span>
            </li>
            <li>
              <strong>Last sync</strong>
              <span>{{last_sync_time}}</span>
            </li>
          </ul>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Sync Logs</h4>
          <p>View synchronization activity for the last 72 hours.</p>
        </div>
        <div class="fields">
          <ul v-if="logs.length > 0">
            <li v-for="(log, index) in logs" :key="`log_${index}`">
              <a href="#" @click.prevent="openLog(log)">{{$formatDate(log.created_at, $DateTime.DATETIME_SHORT)}}</a>
            </li>
          </ul>
          <span v-else>No logs available.</span>
        </div>
      </div>
    </main>
  </aside>
</template>

<script>
import {required} from 'vuelidate/lib/validators'

export default {
  computed: {
    integration() {
      return this.$store.state.integrations.find(i => i.integration_type === 'WORKDAY') || {};
    },
    status_label() {
      switch(this.integration.status) {
        case "INACTIVE":
          return 'Inactive';
        case "ACTIVE":
          return 'Active';
      }
    },
    active() {
      return this.integration.status === 'ACTIVE' ? true : false;
    },
    last_sync_time() {
      if(!this.integration.metadata.last_sync) return 'Pending';

      return this.$formatDate(this.integration.metadata.last_sync, this.$DateTime.DATETIME_SHORT);
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      logs: [],
      add_creds: false,
      cred_form: null
    }
  },
  validations: {
  },
  watch: {
    add_creds(val) {
      if(val === true) {
        this.$nextTick(() => {
          this.initForm();
        })
      }
    }
  },
  mounted() {

    this.populateLogs()

    if(!this.active) {
      this.add_creds = true;
    }
  },
  methods: {
    async tryAuth() {
      this.$store.commit('loading', true)

      this.cred_form.submit(
        '/v1/team/integrations/workday/set_credentials',
        {
          headers: {
            'Authorization': `Bearer ${this.$store.state.user_token}`
          }
        },
        async (status, resp) => {
          if(resp.ok === true) {
            await this.$store.dispatch('populateIntegrations')
            this.add_creds = false;
          }
          else {
            //invalid creds
          }

          this.$store.commit('loading', false)
        },
        (error) => {
          this.$store.commit('loading', false)
          console.error(error)
        }
      )
    },
    initForm() {
      this.cred_form = this.$vgs.init((state) => {
        
      })

      this.cred_form.field('#report_url', {
        type: 'text',
        name: 'report_url',
        validations: [
          'required'
        ],
        css: {
          fontSize: '16px',
          padding: `6px 12px`,
          boxSizing: `border-box`,
          color: `#495057`
        }
      })

      this.cred_form.field('#username', {
        type: 'text',
        name: 'username',
        validations: [
          'required'
        ],
        css: {
          fontSize: '16px',
          padding: `6px 12px`,
          boxSizing: `border-box`,
          color: `#495057`
        }
      })

      this.cred_form.field('#password', {
        type: 'password',
        name: 'password',
        validations: [
          'required'
        ],
        css: {
          fontSize: '16px',
          padding: `6px 12px`,
          boxSizing: `border-box`,
          color: `#495057`
        }
      })
    },
    openLog(log) {
      this.$root.$emit(
        'openModal',
        'Integrations/LogViewer',
        log,
        () => {}
      )
    },
    async populateLogs() {
      if(!_.isEmpty(this.integration)) {
        const resp = await this.$api.Integrations.get_logs(this.integration.id)
        this.logs = resp;
      }
    },
    close() {
      this.$emit('done')
    }
  }
}
</script>

<style lang="scss" scoped>
.dayforce-auth {
  background: #fff;
  box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  padding: 25px;
  max-width: 380px;
  min-height: 50px;

  .form-group {
    margin-bottom: 10px;
  }

  label {
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 5px;
    color: $muted-text;
  }
}
.secure-field {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E3E0DE;
  border-radius: 0.25rem;
  transition: none;

  ::v-deep > iframe {
    height: 100% !important;
    width: 100% !important;
  }
}
.stats {
  > ul {
    display: block;
    margin: 0;
    padding: 0;

    > li {
      display: flex;
      border-bottom: 1px solid $light;
      padding: 5px 0;

      > strong {
        flex: 0 0 35%;
        display: block;
      }

      > span {
        flex: 1 0 1;
        display: block;
      }

      &.status {
        > span {
          > span {
            border-radius: 3px;
            background: $light;
            padding: 3px 5px;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            line-height: 1em;
          }
          &.ACTIVE {
            > span {
              background: $green;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>